import service from "./index";

//人艺人
export const getEntertainer = (data) => {
  return service({
    url: "/open/portal/entertainer/page",
    method: "post",
    data,
  });
};

//详情
export const getDetail = (id) => {
  return service({
    url: `/open/portal/entertainer/getById/${id}`,
    method: "get",
  });
};

//人艺人点击+1
export const clickEntertainer = (id) => {
  return service({
    url: `/open/portal/entertainer/hit/${id}`,
    method: "get",
    id,
  });
};
