<template>
    <div class="content">
      <div @click="checkTitle(item)" v-for="item in titleList" :key="item.id" :class="item.id == checkId? 'content-active':'content-title'">
        {{ item.name }}
      </div>
    </div>
</template>
<script>
export default{
    props: {
    checkId: {
      type: Number,
      default: 0
    }
    },
    data(){
        return{
            titleList:[
            {
                    id:1,
                    name:'创始人',
                    url:'/PeopleEntertainers/founder'
                },
                {
                    id:2,
                    name:'编剧',
                    url:'/PeopleEntertainers/screenwriter'
                },
                {
                    id:3,
                    name:'导演',
                    url:'/PeopleEntertainers/director'
                },
                {
                    id:5,
                    name:'设计',
                    url:'/PeopleEntertainers/devise'
                },
                {
                    id:4,
                    name:'演员',
                    url:'/PeopleEntertainers/actor'
                },
                {
                    id:6,
                    name:'舞美',
                    url:'/PeopleEntertainers/stage'
                }
            ],
        }
    },
    methods:{
        checkTitle(item){
            if(this.checkId !== item.id){
                this.listId = item.id
                this.$router.push(item.url)
            }
        },
    }
}
</script>
<style scoped lang="less">
.content{
    width: 100%;
    height: 1.2rem;
    border-bottom: 0.0133rem solid #E4E6EB;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    line-height: 1.2rem;
    display: flex;
    justify-content: space-around;
    .content-title{
      font-size: 0.4rem;
    }
    .content-active{
        color: #F9680D;
        font-size: 0.4rem;
    }
}
</style>