<template>
  <div class="mid">
        <span v-for="letter in alphabet" :key="letter" :class="{ 'active': selectedLetter === letter }"
            @click="selectLetter(letter)">
            {{ letter }}
        </span>
  </div>
</template>
<script>
export default{
    data(){
        return{
            selectedLetter:'全',
            alphabet: ['全', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        }
    },
    methods:{
        selectLetter(letter) {
                this.selectedLetter = letter;
                if(letter == '全'){
                    this.$parent.form.firstLetter = ''
                    this.$parent.getList()
                }else{ 
                    this.$parent.form.firstLetter = letter
                    this.$parent.getList()
                }
		},
    }
}
</script>
<style scoped lang="less">
.mid {
    height: 0.6667rem;
    width: 100%;
    display: flex;
    font-size: 0.3rem;
    justify-content: space-between;
    flex-direction: column;
    span {
        cursor: pointer;
        height: 0.3rem;
        width: 0.3rem;
        line-height: 0.3rem;
        margin-bottom: 0.1rem; /* 添加垂直间距 */
    } 

    .active {
        height: 0.3rem;
        line-height: 0.3rem;
        background-color: #FA7928;
        font-size: 0.3rem;
        color: #ffffff;
    }
}
</style>